import { events } from "utils/events";
import { trackEvent } from "@services/analysis-services";

export const brandPagePaths = [
  "/[brandId]",
  "/[brandId]/assessments",
  "/[brandId]/assessments/[assessmentsID]",
];

export const nonBrandPagePaths = [
  "/",
  "/assessments",
  "/assessments/[assessmentsID]",
];

export const isBrandIdOnNonBrandPage = (pathname, unRegisteredUser) => {
  return (
    nonBrandPagePaths.includes(pathname) &&
    unRegisteredUser &&
    unRegisteredUser.hasOwnProperty("brandId")
  );
};

export const isBrandMismatch = (pathname, unRegisteredUser, brandId) => {
  return (
    brandPagePaths.includes(pathname) &&
    unRegisteredUser &&
    unRegisteredUser.hasOwnProperty("brandId") &&
    unRegisteredUser.brandId !== brandId
  );
};

export const hasBrandId = (pathname, unRegisteredUser) => {
  return (
    brandPagePaths.includes(pathname) &&
    unRegisteredUser &&
    unRegisteredUser.hasOwnProperty("brandId")
  );
};

export const isAuth = (brandId, unRegisteredUser) => {
  if (brandId) {
    return (
      unRegisteredUser &&
      unRegisteredUser.hasOwnProperty("brandId") &&
      unRegisteredUser.hasOwnProperty("token") &&
      unRegisteredUser.token !== null &&
      unRegisteredUser.brandId === brandId
    );
  }
  return (
    unRegisteredUser &&
    !unRegisteredUser.hasOwnProperty("brandId") &&
    unRegisteredUser.hasOwnProperty("token") &&
    unRegisteredUser.token !== null
  );
};

export const whyUs = [
  {
    id: 1,
    image: "team-s.svg",
    title: "Expert Team",
    description:
      "Our team comprises of professionals including special educators, occupational therapists, speech therapists, ABA therapists and child psychologists",
    style: {
      bgColor: "rgba(79, 191, 215, 0.06)",
      borderColor: "#4FBFD7",
    },
  },
  {
    id: 2,
    image: "rate-s.svg",
    title: "Affordable Prices",
    description:
      "Our aim is to provide high quality care for your child which does not burn a hole in your pocket",
    style: {
      bgColor: "rgba(79, 215, 133, 0.06)",
      borderColor: "#4FD785",
    },
  },
  {
    id: 3,
    image: "progress-s.svg",
    title: "Regular Reports",
    description:
      "We share regular growth and progress reports for parents to better understanding their child and their needs",
    style: {
      bgColor: "rgba(196, 118, 241, 0.06)",
      borderColor: "#C476F1",
    },
  },
];

export const offerings = [
  {
    id: 1,
    title: "Assessments",
    list: [
      "Psychological assessments",
      "SLD Assessment",
      "IQ assessments",
      "ADHD Assessment",
      "Autism Screening",
    ],
  },
  {
    id: 2,
    title: "Services",
    list: [
      "Occupational Therapy",
      "Speech Therapy",
      "Special Education",
      "Parent & Child Counselling",
      "Behaviour Modification",
    ],
  },
];

export const therapists = [
  {
    id: 1,
    profileImg: "/images/talat.png",
    name: "Dr. Talat Praveen",
    shortBio: "Occupational Therapist",
    url: "https://www.lissun.app/therapist-detail/109",
  },
  {
    id: 2,
    profileImg: "/images/Parul.jpeg",
    name: "Parul",
    shortBio: "Speech Therapist",
    url: "https://www.lissun.app/therapist-detail/117",
  },
  {
    id: 3,
    profileImg: "/images/yashba.png",
    name: "Yashba Fatima",
    shortBio: "Special Educator Counselor",
    url: "https://www.lissun.app/therapist-detail/108",
  },
  {
    id: 4,
    profileImg: "/images/rahul.png",
    name: "Rahul Bhushan",
    shortBio: "Special Educator Counselor",
    url: "https://www.lissun.app/therapist-detail/111",
  },
  {
    id: 5,
    profileImg: "/images/shruti.jpeg",
    name: "Shruti",
    shortBio: "Occupational Therapist",
    url: "https://www.lissun.app/therapist-detail/131",
  },
  {
    id: 6,
    profileImg: "/images/jyoti.jpeg",
    name: "Jyoti Gautam",
    shortBio: "Child Psychologist",
    url: "https://www.lissun.app/therapist-detail/130",
  },
  {
    id: 7,
    profileImg: "/images/bhavya.jpeg",
    name: "Bhavya Mittal",
    shortBio: "Occupational Therapist",
    url: "https://www.lissun.app/therapist-detail/127",
  },
  {
    id: 8,
    profileImg: "/images/nidhi.jpeg",
    name: "Nidhi Kumari",
    shortBio: "Speech Therapist",
    url: "https://www.lissun.app/therapist-detail/124",
  },
  {
    id: 9,
    profileImg: "/images/nitin.jpeg",
    name: "Nitin Patil",
    shortBio: "Special Educator Counselor",
    url: "https://www.lissun.app/therapist-detail/123",
  },
  {
    id: 10,
    profileImg: "/images/nikita.jpeg",
    name: "Nikita",
    shortBio: "Clinical Psychologist",
    url: "https://www.lissun.app/therapist-detail/122",
  },
  {
    id: 11,
    profileImg: "/images/Sonal.jpeg",
    name: "Sonal",
    shortBio: "Clinical Psychologist",
    url: "https://www.lissun.app/therapist-detail/19",
  },
  {
    id: 12,
    profileImg: "/images/Reena.jpeg",
    name: "Reena",
    shortBio: "Speech Therapist",
    url: "https://www.lissun.app/therapist-detail/129",
  },
];

export const social = [
  {
    id: 1,
    icon: "LinkedIn123.svg",
    url: "https://www.linkedin.com/company/lissun",
  },
  {
    id: 2,
    icon: "Insta123.svg",
    url: "https://www.instagram.com/sunshinebylissun/",
  },
  {
    id: 3,
    icon: "YT123.svg",
    url: "https://www.youtube.com/channel/UCb2QXTsk4KUClxLZab4-sTA",
  },
  {
    id: 4,
    icon: "Fb123.svg",
    url: "https://www.facebook.com/profile.php?id=100095340968307",
  },
];

export const gallery = [
  "gallery-1.jpeg",
  "gallery-2.jpeg",
  "gallery-3.jpeg",
  "gallery-4.jpeg",
  "gallery-5.jpeg",
  "gallery-6.jpeg",
  "gallery-7.jpeg",
  "gallery-8.jpeg",
];

export const sendEventObj = (newValues, eventKey) => {
  const eventName = String(events.entity[eventKey].event_name);
  const attributes = events.entity[eventKey]["attributes_" + eventKey];
  const newObj = Object.keys(attributes).reduce((acc, key) => {
    const newKey = String(attributes[key]);
    if (newValues[key]) acc[newKey] = newValues[key];
    return acc;
  }, {});

  console.log("{ [eventName]: newObj }", { [eventName]: newObj });

  trackEvent({ [eventName]: newObj });
};

export const locationOptions = [
  { title: "Delhi (East Patel Nagar)", value: "Delhi (East Patel Nagar)" },
  { title: "Delhi (Indirapuram)", value: "Delhi (Indirapuram)" },
  { title: "Delhi (Pitampura)", value: "Delhi (Pitampura)" },
  { title: "Gurgaon (Sector 51)", value: "Gurgaon (Sector 51)" },
  { title: "Lucknow (Gomti Nagar)", value: "Lucknow (Gomti Nagar)" },
  { title: "Pune (Magarpatta) ", value: "Pune (Magarpatta) " },
];

export const preferedTimings = [
  { title: "Morning" },
  { title: "Afternoon" },
  { title: "Evening" },
];

export const reasonOptions = [
  { title: "Autism Therapy" },
  { title: "Speech Therapy" },
  { title: "Occupational Therapy" },
  { title: "Special Education" },
  { title: "Psychological Counselling" },
  { title: "ABA/Behavioral Therapy" },
  { title: "Physiotherapy (Upto Age 6)" },
  { title: "Group Therapy" },
  { title: "Parent Counselling" },
];

export const triggerGoogleAddConversion = (label) => {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID_ADS}`;

  // Append the script to the head of the document
  document.head.appendChild(script);

  // Execute the gtag event when the script is loaded
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", `${process.env.NEXT_PUBLIC_GA_TRACKING_ID_ADS}`);

    // Trigger the conversion event
    gtag("event", "conversion", {
      send_to: `${process.env.NEXT_PUBLIC_GA_TRACKING_ID_ADS}/${label}`,
    });
  };
};

export const googleAdsLabel = {
  SUNSINE_FORM_SUBMITTED_LABEL: "_RibCKaA5owZELfFzM4p",
  SUNSINE_OTP_VERIFIED_LABEL: "KCerCO7alY4ZELfFzM4p",
  SUNSINE_PAGE_VIEW_LABEL: "IBoECMLyno4ZELfFzM4p",
  SUNSHINE_PAGE_IM_INTERESTED: "kk5iCKSxp7cZELfFzM4p",
};

export const notAllowedAssessmentUrls = ["/sunshine", "/ypay", "/education"];
